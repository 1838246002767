import { FunctionComponent, HTMLAttributes, useContext, useMemo } from 'react';

import { GoogleWebfontsContext } from '@context/google-webfonts';

import { Block } from '@typings/block';
import { getBlockDataIsHtml } from '@utilities/block';
import { extractFontFamiliesFromHtml, getFirstFontFamily } from '@utilities/font';

export type HtmlFontLoaderProps = {
  block: Block;
} & Pick<HTMLAttributes<HTMLElement>, 'children'>;

export const HtmlFontLoader: FunctionComponent<HtmlFontLoaderProps> = (props) => {
  const { block, children } = props;
  const { googleWebfonts } = useContext(GoogleWebfontsContext);

  const data = getBlockDataIsHtml(block.data) ? block.data : null;

  const fontFamilies = useMemo(() => (data ? extractFontFamiliesFromHtml(data.html) : []), [data]);

  const googleFontFamilies = useMemo(
    () => [
      ...new Set(
        fontFamilies.filter((fontFamily) =>
          googleWebfonts.find(
            (googleWebFont) =>
              googleWebFont.family.toLowerCase() === getFirstFontFamily(fontFamily).toLowerCase()
          )
        )
      )
    ],
    [fontFamilies, googleWebfonts]
  );

  if (!data) return null;

  return (
    <>
      {googleFontFamilies.map((googleFontFamily) => {
        const font = getFirstFontFamily(googleFontFamily);
        return (
          <link
            data-testid="google-font-link"
            href={`https://fonts.googleapis.com/css2?family=${font}:wght@400;500;700&display=swap`}
            key={font}
            rel="stylesheet"
          />
        );
      })}
      {children}
    </>
  );
};
