import { PermissionLevels } from '@enums/permission-levels';

export const permissionLevels: PermissionLevels[] = [
  PermissionLevels.Admin,
  PermissionLevels.Collaborator,
  PermissionLevels.Empty,
  PermissionLevels.Guest,
  PermissionLevels.None,
  PermissionLevels.Owner
];
