import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import Player from 'video.js/dist/types/player';

import { Block, BlockVideo } from '@typings/block';
import { getBlockDataIsVideo } from '@utilities/block';

interface VideoRenderProps extends HTMLAttributes<HTMLDivElement> {
  block: Block;
  data?: BlockVideo | null;
}

interface InternalVideoRenderProps extends VideoRenderProps {
  data: BlockVideo;
}

const UnstyledVideoRender: FunctionComponent<InternalVideoRenderProps> = (props) => {
  const { block, data, ...otherProps } = props;

  const { key } = block;
  const { cdnUrl, height, mimeType, name, width } = data;

  const player = useRef<Player | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const scrollPosition = useRef<number>(0); // state doesn't work well, prefer ref

  /**
   * Workaround for https://github.com/videojs/video.js/issues/3355
   */
  const handleFullscreen = () => {
    if (!!document.fullscreenElement === false && scrollPosition.current > 0) {
      setTimeout(() => {
        window.scrollTo(0, scrollPosition.current);
      }, 100);
    }
  };

  const handleScrollPosition = () => {
    if (!!document.fullscreenElement === false) {
      scrollPosition.current = window.scrollY;
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreen);
    document.addEventListener('scroll', handleScrollPosition, { passive: true });

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreen);
      document.removeEventListener('scroll', handleScrollPosition);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * https://videojs.com/guides/options/
   * */
  const options = useMemo(() => {
    return {
      aspectRatio: `${width}:${height}`,
      autoplay: false,
      controlBar: {
        fullscreenToggle: true,
        pictureInPictureToggle: false,
        remainingTimeDisplay: true,
        volumePanel: true
      },
      controls: true,
      fluid: true,
      loop: false,
      muted: false,
      preload: 'auto',
      responsive: true,
      sources: [
        {
          src: cdnUrl,
          type: mimeType
        }
      ]
    };
  }, [cdnUrl, height, mimeType, width]);

  useEffect(() => {
    if (ref.current) {
      const loadVideoJs = async () => {
        /**
         * https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading#with-external-libraries
         */
        // eslint-disable-next-line unicorn/no-await-expression-member
        const videojs = (await import('video.js')).default;

        if (ref.current) {
          const videoElement = document.createElement('video-js');
          ref.current.innerHTML = '';
          ref.current.append(videoElement);
          player.current = videojs(videoElement, options);
        }
      };

      loadVideoJs();
    }
  }, [options, ref]);

  return (
    <div
      {...otherProps}
      className={classNames({
        video: true,
        [`video--${mimeType}`]: mimeType,
        [`${otherProps?.className}`]: !!otherProps?.className
      })}
      data-testid={key}
      data-vjs-player
      id={key}
      ref={ref}
    >
      {/*
        NOTE: <video> element here is for SSR SEO (and to a lesser extent, non-JS enabled browsers).
        It gets remove by the `innerHTML` inside the `useEffect` above.
      */}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        autoPlay={false}
        controls
        disablePictureInPicture
        loop={false}
        muted={false}
        playsInline
        style={{
          aspectRatio: `${width} / ${height}`
        }}
        title={name}
      >
        <source src={cdnUrl} type={mimeType}></source>
      </video>
    </div>
  );
};

const StyledVideoRender = styled(UnstyledVideoRender)``;

/**
 * https://videojs.com/
 * https://videojs.com/guides/react/
 * https://github.com/vercel/next.js/tree/canary/examples/with-videojs
 *
 * @param props VideoRenderProps
 * @returns FunctionComponent<VideoRenderProps>
 */
export const VideoRender: FunctionComponent<VideoRenderProps> = (props) => {
  const { block, ...otherProps } = props;

  const data = getBlockDataIsVideo(block.data) ? block.data : null;
  if (!data) return null;

  return <StyledVideoRender {...otherProps} block={block} data={data} />;
};
