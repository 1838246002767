import { NextApiRequest, NextApiResponse } from 'next';

import { memoryCache } from '@cache/memory-cache';
import { permissionLevels } from '@constants/permission-level';
import { PermissionLevels } from '@enums/permission-levels';
import { ApiDatum } from '@typings/api';
import { PermissionLevel } from '@typings/permission-level';
import logger from '@utilities/logger';
import { getAccessTokenFromBearer, getRefreshedToken, getTokenUserKey } from '@utilities/oauth2';

const getHeader = (header: string | string[] | undefined): string | undefined => {
  return Array.isArray(header) ? header[0] : header;
};

/**
 * Get whether the user is an admin.
 * @param level PermissionLevel | undefined
 * @returns boolean
 */
export const getIsAdmin = (level?: PermissionLevel): boolean => {
  if (!level) return false;
  return level === PermissionLevels.Admin || level === PermissionLevels.Owner;
};

/**
 * Get whether the user is anonymous.
 * @param level PermissionLevel | undefined
 * @returns boolean
 */
export const getIsAnonymous = (level?: PermissionLevel): boolean => {
  if (!level) return true;
  return level === PermissionLevels.Empty || level === PermissionLevels.None;
};

/**
 * Get whether the user is a collaborator.
 * @param level PermissionLevel | undefined
 * @returns boolean
 */
export const getIsCollaborator = (level?: PermissionLevel): boolean => {
  if (!level) return false;
  return level === PermissionLevels.Collaborator;
};

/**
 * Get whether the user is a guest.
 * @param level PermissionLevel | undefined
 * @returns boolean
 */
export const getIsGuest = (level?: PermissionLevel): boolean => {
  if (!level) return false;
  return level === PermissionLevels.Guest;
};

/**
 * Get whether the permission level is a known one
 * @param level PermissionLevel | undefined
 * @returns boolean
 */
export const getIsKnownPermissionLevel = (level?: PermissionLevels): boolean => {
  if (level === undefined) return false;
  return permissionLevels.includes(level);
};

/**
 * A promise to GET user [permission_level](https://github.com/brandfolder/boulder/blob/b8f282bd9399639349aeb284d3be1a0eeced43a5/config/routes.rb#L832)
 * from boulder. Caches the users permission level for 1 minute. Server-side (Node) only.
 *
 * @param req NextApiRequest
 * @param res NextApiRequest
 * @param brandguideKey string | undefined
 * @returns Promise<ApiDatum<PermissionLevel>>
 */
export const fetchPermissionLevel = async (
  req: NextApiRequest,
  res: NextApiResponse,
  brandguideKey?: string
): Promise<ApiDatum<PermissionLevel>> => {
  let cacheKey: string | undefined = undefined;
  const emptyPermissionLevel: ApiDatum<PermissionLevel> = {
    data: {
      attributes: {},
      id: PermissionLevels.Empty,
      type: 'permission_level'
    }
  };

  try {
    const { headers } = req;

    const key = brandguideKey || getHeader(headers['x-brandguide-key']);
    if (!key) return emptyPermissionLevel;

    const { authorization } = headers;
    const { accessToken } = await getRefreshedToken(req, res);
    if (!authorization && !accessToken) return emptyPermissionLevel;

    const userKey = authorization
      ? getTokenUserKey(getAccessTokenFromBearer(authorization))
      : getTokenUserKey(accessToken);
    if (!userKey) return emptyPermissionLevel;

    const url = `/api/v4/brandguides/${key}/permission_level`;
    cacheKey = `${url}-${userKey}`;

    let data = memoryCache.get(cacheKey) as ApiDatum<PermissionLevel> | undefined;
    if (data) return data;

    const response = await fetch(`${process.env.BRANDFOLDER_BOULDER_URL}${url}`, {
      headers: {
        Authorization: authorization || `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });

    if (response.ok) {
      data = (await response.json()) as ApiDatum<PermissionLevel>;
      if (!data.data.id || !getIsKnownPermissionLevel(data.data.id as PermissionLevels)) {
        return emptyPermissionLevel;
      }

      memoryCache.set(cacheKey, data, { ttl: 1 * 60 * 1000 }); // 1 minute
      return data;
    } else {
      throw new Error('Error fetching permission level.');
    }
  } catch (error) {
    if (cacheKey) memoryCache.delete(cacheKey);
    logger.error(error);
    return emptyPermissionLevel;
  }
};
