import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

import { BrandguideContext } from '@context/brandguide';
import { OAuthContext } from '@context/oauth';
import { PermissionLevels } from '@enums/permission-levels';
import { ApiDatum } from '@typings/api';
import { PermissionLevel } from '@typings/permission-level';
import { poll } from '@utilities/fetch';
import {
  getIsAdmin,
  getIsAnonymous,
  getIsCollaborator,
  getIsGuest
} from '@utilities/permission-level';

interface PermissionLevelContextState {
  editable: boolean;
  isAdmin: boolean;
  isAnonymous: boolean;
  isCollaborator: boolean;
  isGuest: boolean;
  permissionLevel: PermissionLevels;
  permissionLevelLoading: boolean;
}

const defaultPermissionLevel: PermissionLevelContextState = {
  editable: false,
  isAdmin: false,
  isAnonymous: true,
  isCollaborator: false,
  isGuest: false,
  permissionLevel: PermissionLevels.Empty,
  permissionLevelLoading: false
};

export const PermissionLevelContext =
  createContext<PermissionLevelContextState>(defaultPermissionLevel);

interface PermissionLevelContextProps {
  children: ReactNode;
}

export const PermissionLevelProvider: FunctionComponent<PermissionLevelContextProps> = ({
  children
}) => {
  const { brandguide } = useContext(BrandguideContext);
  const { authed } = useContext(OAuthContext);

  const [isAdmin, setIsAdmin] = useState(defaultPermissionLevel.isAdmin);
  const [isAnonymous, setIsAnonymous] = useState(defaultPermissionLevel.isAnonymous);
  const [isCollaborator, setIsCollaborator] = useState(defaultPermissionLevel.isCollaborator);
  const [isGuest, setIsGuest] = useState(defaultPermissionLevel.isGuest);
  const [permissionLevel, setPermissionLevel] = useState<PermissionLevels>(
    defaultPermissionLevel.permissionLevel
  );
  const [permissionLevelLoading, setPermissionLevelLoading] = useState(
    defaultPermissionLevel.permissionLevelLoading
  );

  const resetPermissionLevel = () => {
    setIsAdmin(getIsAdmin(defaultPermissionLevel.isAdmin));
    setIsAnonymous(getIsAnonymous(defaultPermissionLevel.isAnonymous));
    setIsCollaborator(getIsCollaborator(defaultPermissionLevel.isCollaborator));
    setIsGuest(getIsGuest(defaultPermissionLevel.isGuest));
    setPermissionLevel(defaultPermissionLevel.permissionLevel);
  };

  const fetchPermissionLevel = async () => {
    try {
      setPermissionLevelLoading(true);

      const response = await fetch(`/api/v4/brandguides/${brandguide?.key}/permission_level`);
      if (response.ok) {
        const json = (await response.json()) as ApiDatum<PermissionLevel>;
        const level = json.data.id as PermissionLevels;

        setIsAdmin(getIsAdmin(level));
        setIsAnonymous(getIsAnonymous(level));
        setIsCollaborator(getIsCollaborator(level));
        setIsGuest(getIsGuest(level));
        setPermissionLevel(level);
      } else {
        resetPermissionLevel();
      }
    } catch {
      resetPermissionLevel();
    } finally {
      setPermissionLevelLoading(false);
    }
  };

  useEffect(() => {
    if (authed && brandguide) {
      const fiveMinutes = 5 * 60 * 1000;
      poll(fetchPermissionLevel, fiveMinutes);
    }
  }, [authed, brandguide]); // eslint-disable-line react-hooks/exhaustive-deps

  const editable = isAdmin && authed;

  return (
    <PermissionLevelContext.Provider
      value={{
        editable,
        isAdmin,
        isAnonymous,
        isCollaborator,
        isGuest,
        permissionLevel,
        permissionLevelLoading
      }}
    >
      {children}
    </PermissionLevelContext.Provider>
  );
};
