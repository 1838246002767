import { CodeEditorLanguages } from '@brandfolder/react';
import dynamic from 'next/dynamic';
import { Fragment, FunctionComponent, ReactNode, useContext, useState } from 'react';

import { defaultHtml } from '@constants/block';
import { BlockContext } from '@context/blocks';
import { DragAndDropContext } from '@context/drag-and-drop';
import { PermissionLevelContext } from '@context/permission-level';
import { PreviewContext } from '@context/preview';
import { PublishContext } from '@context/publish';
import { BlockTypes } from '@enums/blocks';
import { Block } from '@typings/block';
import {
  getBlockDataIsColor,
  getBlockDataIsDoc,
  getBlockDataIsHtml,
  getBlockDataIsImage,
  getBlockDataIsPdf,
  getBlockDataIsVideo,
  updateBlock
} from '@utilities/block';

import { ColorRender } from './color/color-render';
import { DocRender } from './doc/doc-render';
import { HtmlRender } from './html/html-render';
import { ImageRender } from './image/image-render';
import { SectionRender } from './section/section-render';
import { VideoRender } from './video/video-render';

/**
 * These components are only needed when editing client-side, so they should not be
 * contributing to the JS page load, and instead can be loaded via code splitting.
 * Be sure to check `yarn build` after modifying.
 */
const BlockEditor = dynamic(() => import('./editor/block-editor'), { ssr: false });
const CodeEditor = dynamic(() => import('./code/code-editor'), { ssr: false });
const PdfRender = dynamic(() => import('./pdf/pdf-render'), { ssr: false });
const TinyMceInline = dynamic(() => import('../wysiwyg/tiny-mce-inline'), { ssr: false });

interface BlockRenderProps {
  block: Block;
  index: number;
  first?: boolean;
  last?: boolean;
  only?: boolean;
  sectionId?: string;
}

export const BlockRender: FunctionComponent<BlockRenderProps> = (props) => {
  const { block, first, index, last, only, sectionId } = props;
  const { data, key, parentKey, type } = block;

  const { blocks, setBlocks } = useContext(BlockContext);
  const { dragging } = useContext(DragAndDropContext);
  const { editable } = useContext(PermissionLevelContext);
  const { preview } = useContext(PreviewContext);
  const { setPublish } = useContext(PublishContext);

  const html = type === BlockTypes.Html;

  const getDefaultEdit = (): boolean => {
    if (!html) return false;
    if (getBlockDataIsHtml(data) && data.html === defaultHtml) return true;
    return false;
  };

  const [edit, setEdit] = useState(getDefaultEdit());

  const editing = editable && !preview;

  if (!sectionId && parentKey) return null;

  const renderEditor = (children: ReactNode): ReactNode => {
    return (
      <BlockEditor
        block={block}
        edit={html ? edit : undefined}
        first={first}
        index={index}
        last={last}
        only={only}
        setEdit={html ? setEdit : undefined}
      >
        {children}
      </BlockEditor>
    );
  };

  return (
    // must be fragment so we don't render superfluous HTML elements
    // so, DO NOT replace `<Fragment>` with `<div>`, `<section>`, etc
    <Fragment>
      {editing ? (
        <>
          {type === BlockTypes.Color && getBlockDataIsColor(data)
            ? renderEditor(<ColorRender block={block} id={key} />)
            : type === BlockTypes.Excel && getBlockDataIsDoc(data)
              ? renderEditor(<DocRender block={block} />)
              : type === BlockTypes.Html && getBlockDataIsHtml(data)
                ? renderEditor(
                    edit ? (
                      <CodeEditor block={block} label="HTML" language={CodeEditorLanguages.Html} />
                    ) : (
                      <HtmlRender block={block} />
                    )
                  )
                : type === BlockTypes.Image && getBlockDataIsImage(data)
                  ? renderEditor(<ImageRender block={block} />)
                  : !parentKey && type === BlockTypes.Section
                    ? renderEditor(<SectionRender block={block} />)
                    : type === BlockTypes.Pdf && getBlockDataIsPdf(data)
                      ? renderEditor(<PdfRender block={block} />)
                      : type === BlockTypes.PowerPoint && getBlockDataIsDoc(data)
                        ? renderEditor(<DocRender block={block} />)
                        : type === BlockTypes.Text && getBlockDataIsHtml(data)
                          ? renderEditor(
                              <TinyMceInline
                                disabled={dragging}
                                block={block}
                                onBlur={(content) => {
                                  if (data.html !== content) {
                                    setBlocks([
                                      ...updateBlock({ ...block, data: { html: content } }, blocks)
                                    ]);
                                    setPublish(true);
                                  }
                                }}
                                sectionId={sectionId}
                              />
                            )
                          : type === BlockTypes.Video && getBlockDataIsVideo(data)
                            ? renderEditor(<VideoRender block={block} />)
                            : type === BlockTypes.Word && getBlockDataIsDoc(data)
                              ? renderEditor(<DocRender block={block} />)
                              : null}
        </>
      ) : type === BlockTypes.Color && getBlockDataIsColor(data) ? (
        <ColorRender block={block} id={key} />
      ) : type === BlockTypes.Excel && getBlockDataIsDoc(data) ? (
        <DocRender block={block} id={key} />
      ) : type === BlockTypes.Image && getBlockDataIsImage(data) ? (
        <ImageRender block={block} />
      ) : type === BlockTypes.Pdf && getBlockDataIsPdf(data) ? (
        <PdfRender block={block} id={key} />
      ) : type === BlockTypes.PowerPoint && getBlockDataIsDoc(data) ? (
        <DocRender block={block} id={key} />
      ) : type === BlockTypes.Section ? (
        <SectionRender block={block} />
      ) : (type === BlockTypes.Text || type === BlockTypes.Html) && getBlockDataIsHtml(data) ? (
        <HtmlRender block={block} />
      ) : type === BlockTypes.Video && getBlockDataIsVideo(data) ? (
        <VideoRender block={block} />
      ) : type === BlockTypes.Word && getBlockDataIsDoc(data) ? (
        <DocRender block={block} id={key} />
      ) : null}
    </Fragment>
  );
};
