import { useRouter } from 'next/router';
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

import { OAuthContext } from '@context/oauth';
import { poll } from '@utilities/fetch';
import { redirectToSignOut } from '@utilities/oauth2';

const RefreshSession: FunctionComponent = () => {
  const abortController = new AbortController();

  const router = useRouter();

  const [mounted, setMounted] = useState(false);

  const refreshAccessToken = async () => {
    try {
      const response = await fetch('/api/session', {
        method: 'GET',
        signal: abortController.signal
      });

      if (!response.ok && !abortController.signal.aborted) {
        await redirectToSignOut(router);
      }
    } catch {
      if (!abortController.signal.aborted) {
        await redirectToSignOut(router);
      }
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      const fiveMinutes = 5 * 60 * 1000;
      poll(refreshAccessToken, fiveMinutes);

      return () => {
        abortController.abort();
      };
    }
  }, [mounted]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

interface SessionContextState {}

export const SessionContext = createContext<SessionContextState>({});

interface SessionContextProps {
  children: ReactNode;
}

export const SessionProvider: FunctionComponent<SessionContextProps> = ({ children }) => {
  const { authed } = useContext(OAuthContext);

  return (
    <SessionContext.Provider value={{}}>
      {children}
      {authed && <RefreshSession />}
    </SessionContext.Provider>
  );
};
